import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import ProductsSearch from '../components/ProductsSearch/ProductsSearch';
import { ProductSearchResultsProvider } from '../context/ProductSearchResultsContext';
import { staticPageRedirection } from '../utils/staticPagesRedirection';
import { useI18next } from 'gatsby-plugin-react-i18next';
import { PRODUCTS_FR_LANGUAGES } from '../constants/sitesLanguagesGroup';
import Alternate from '../components/Seo/Alternate';
import { prepareAlternateData } from '../utils/prepareAlternateData';

const ProductsPage = ({
    data: {
        drupal: {
            product_search: { schema: schema },
        },
    },
}) => {
    const { language } = useI18next();
    const languagesWithUrls = [];
    process.env.LANGUAGES.split(',').forEach((language: string) =>
        languagesWithUrls.push(prepareAlternateData(language, 'products'))
    );

    useEffect(() => {
        staticPageRedirection(language, 'products', PRODUCTS_FR_LANGUAGES);
    }, []);

    return (
        <ProductSearchResultsProvider>
            <Alternate languages={languagesWithUrls} />
            <ProductsSearch schema={JSON.parse(schema)} />
        </ProductSearchResultsProvider>
    );
};

export default ProductsPage;

export const query = graphql`
    query ($language: String!) {
        drupal {
            product_search(language: $language) {
                schema
            }
        }
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
